import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";

// Assets
// import ContactImg1 from "../assets/img/contact-1.png";
// import ContactImg2 from "../assets/img/contact-2.png";
// import ContactImg3 from "../assets/img/contact-3.png";
import add6 from "../assets/img/add/add6.webp"
import add7 from "../assets/img/add/add7.jpg"
import add10 from "../assets/img/add/add10.jpg"



export default function Contact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [IsSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault();
    const data = { name, email, subject, message };
    try{
      const response = await axios.post("http://127.0.0.1:8000/api/contact/", data);
      console.log(response.data);

      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setIsSubmitted(true)

      setTimeout(()=> setIsSubmitted(false), 5000);
    }
    catch(error){
      console.error(error);
    }

  }
    
    

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          {IsSubmitted && <div className="successmessage"> Your message has been sent successfully!</div>}
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={handleSubmit}> 
                <label className="font13">Full name:</label>
                <input type="text" id="fname" required value={name} onChange={(e) => setName(e.target.value)} name="fname" className="font20 extraBold" />
                <label className="font13">Email:</label>
                <input type="text" id="email" required value={email} onChange={(e) => setEmail(e.target.value)} name="email" className="font20 extraBold" />
                <label className="font13">Subject:</label>
                <input type="text" id="subject" required value={subject} onChange={(e) => setSubject(e.target.value)} name="subject" className="font20 extraBold" />
                <textarea rows="4" cols="50" type="text"  required value={message} onChange={(e) => setMessage(e.target.value)} id="message" name="message" className="font20 extraBold" />

                <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message"  className="pointer animate radius8" style={{ maxWidth: "220px" }} />
              </SumbitWrapper>

              </Form>
              
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={add6} alt="office" className="radius6"  style={{width: "20rem"}}/>
                </ContactImgBox>
                <ContactImgBox>
                  <img src={add7} alt="office" className="radius6" style={{width: "20rem"}}/>
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={add10} alt="office" className="radius6"  style={{width: "20rem"}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;






