import * as React from "react";
import p_logo from "../svg/p_logo.svg";

function SvgComponent(props) {
  return (
    <p_logo{...props} />
  );
}

export default SvgComponent;
