import React, {useState, useContext } from 'react'
import { Link , useHistory} from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import './views.css'
import Footer from '../Sections/Footer'
import PrivateRoute from '../utils/PrivateRoute';


function Login() {

  const {loginUser} = useContext(AuthContext)
  const history = useHistory()
  const handleSubmit = e => {
    e.preventDefault()
    const email = e.target.email.value
    const password = e.target.password.value

    email.length > 0 && loginUser(email,password).then(() => {
      if (PrivateRoute(email)){
        history.push('/dashboard')
      }
      else{
        history.push('/home')
      }
    })
    // if (email.length > 0) {
    //   loginUser(email, password).then(() => {
    //     if (PrivateRoute(email)) {
    //       history.push('/dashboard');
    //     } else {
    //       history.push('/home');
    //     }
    //   });
    // }


  }




  return (
   <div className="login-container">
      <div className="container">
        <div className="content">
          <h2 className="logo">
            <box-icon name="health"></box-icon>C.G.C
          </h2>

      <div className="text-sci">
        <h2>
          Welcome Back! <br />
          <span>Please Login.</span>
        </h2>

         <p>lorem ipsum</p>

    <div className="social-icons">
      <a href="#">
        <box-icon
          type="logo"
          name="facebook-circle"
          size="md"
          animation="tada-hover"
          color="white"
        ></box-icon>
      </a>
      <a href="#">
        <box-icon
          name="instagram-alt"
          type="logo"
          size="md"
          animation="tada-hover"
          color="white"
        ></box-icon>
      </a>
      <a href="#">
        <box-icon
          name="twitter"
          type="logo"
          size="md"
          animation="tada-hover"
          color="white"
        ></box-icon>
      </a>
    </div>
  </div>
</div>
<div className="logreg-box">
  <div className="form-box login">
    <form className="login-form" onSubmit={handleSubmit}>
      <h2>Sign In</h2>

      <div className="input-box">
        <span>
          <box-icon type="solid" name="envelope"></box-icon>
        </span>
        <input
          type="email"
          name="email"
          placeholder="example@gmail.com"
          // value={email}
          // onChange={(e) => setEmail(e.target.value)}
        />
        <label>Email</label>
      </div>

      <div className="input-box">
        <span>
          <box-icon name="lock-alt" type="solid"></box-icon>
        </span>
        <input
          type="password"
          name="password"
          placeholder="Password"
          // value={password}
          //onChange={(e) => setPassword(e.target.value)}
        />
        <label>Password</label>
      </div>

      <button type="submit">Sign In</button>

      <div className='login-register'>
        <p>Don't have an Account? <a href="/register">Sign Up</a></p>
      </div>
    </form>
  </div>
</div>
</div>
</div>
    
  

    
  )
}

export default Login




// import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { Hanko } from '@teamhanko/hanko-elements';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const history = useHistory();

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const hanko = new Hanko(process.env.REACT_APP_HANKO_API_URL);

//     try {
//       const result = await hanko.authenticate({ email, password });

//       if (result.status === 'success') {
//         // Authentication successful, redirect to dashboard
//         history.push('/dashboard');
//       } else {
//         // Authentication failed, show error message
//         alert(result.message);
//       }
//     } catch (error) {
//       // Handle error
//       console.error(error);
//     }
//   };

//   return (
//     <div className="login-container">
//       <div className="container">
//         <div className="content">
//           <h2 className="logo">
//             <box-icon name="health"></box-icon>C.G.C
//           </h2>

//           <div className="text-sci">
//             <h2>
//               Welcome Back! <br />
//               <span>Please Login.</span>
//             </h2>

//             <p>lorem ipsum</p>

//             <div className="social-icons">
//               <a href="#">
//                 <box-icon
//                   type="logo"
//                   name="facebook-circle"
//                   size="md"
//                   animation="tada-hover"
//                   color="white"
//                 ></box-icon>
//               </a>
//               <a href="#">
//                 <box-icon
//                   name="instagram-alt"
//                   type="logo"
//                   size="md"
//                   animation="tada-hover"
//                   color="white"
//                 ></box-icon>
//               </a>
//               <a href="#">
//                 <box-icon
//                   name="twitter"
//                   type="logo"
//                   size="md"
//                   animation="tada-hover"
//                   color="white"
//                 ></box-icon>
//               </a>
//             </div>
//           </div>
//         </div>
//         <div className="logreg-box">
//           <div className="form-box login">
//             <form className="login-form" onSubmit={handleSubmit}>
//               <h2>Sign In</h2>

//               <div className="input-box">
//                 <span>
//                   <box-icon type="solid" name="envelope"></box-icon>
//                 </span>
//                 <input
//                   type="email"
//                   name="email"
//                   placeholder="example@gmail.com"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//                 <label>Email</label>
//               </div>

//               <div className="input-box">
//                 <span>
//                   <box-icon name="lock-alt" type="solid"></box-icon>
//                 </span>
//                 <input
//                   type="password"
//                   name="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <label>Password</label>
//               </div>

//               <button type="submit">Sign In</button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;