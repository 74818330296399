import React, { useEffect, useState } from "react";
import styled from "styled-components";
import  useAxios  from "../utils/UseAxios";
import axios from "axios";

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../assets/img/add/1.png";
import AddImage2 from "../assets/img/add/2.png";
import AddImage3 from "../assets/img/add/3.png";
import AddImage4 from "../assets/img/add/4.png";
import physio1 from "../assets/img/add/physio1.jpg"
import add12 from "../assets/img/add/add12.jpg"
import add5 from "../assets/img/add/add5.jpeg"
import add8 from "../assets/img/add/add5.jpeg";







export default function Services() {

  // const [Services, setServices] = React.useState([]);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const axiosInstance = useAxios();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
      
  //     try {
  //       const response = await axiosInstance.get('http://127.0.0.1:8000/api/services');
  //       // const data = await response.json();
  //       setServices(response.data);
  //       setLoading(false);
  //       if (response.data && Array.isArray(response.data)){
  //         setServices(response.data);
  //       }
  //       else{
  //         console.error('Invalid data format received', response.data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching services:', error);
  //       setError(error);
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [axiosInstance]);


  const Services = () => {
    const [services, setServices] = React.useState([]);

    useEffect(() => {
      let isMounted = true;
      axios.get('http://127.0.0.1:8000/api/services')
      .then(response => {
        if (isMounted){
          setServices(response.data);
        }
       
      })
      .catch(error => {
        console.error('Error fetching Services',error);
      });

      return () => {
        isMounted = false;
      }
    }, [services]);
  }

  return (

    <Wrapper id="services">
       {/* {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>} */}
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            {/* <p className="font13">
              {" cvvvc"}
            </p> */}
          </HeaderInfo>
          
          <ServiceBoxRow className="flex" >
            {Array.isArray(Services) && Services.length > 0 ? (
              Services.map((service) => (
                <ServiceBox
                  key={service.id}
                  title={service.title}
                  description={service.description}
                  image={service.image}
                />

              )) 
            ) : (
              <ServiceBoxWrapper>
              <ServiceBox
                icon="heart"
                title="Overnight Care"
                subtitle="When sleep disturbances or nighttime anxieties 
                arise, our compassionate and qualified overnight caregivers ensure your loved one receives the support they need for a safe and restful night."
              />
            </ServiceBoxWrapper>
            )}
              
           


            <ServiceBoxWrapper>
              <ServiceBox
                icon="heart"
                title="Home Care Services"
                subtitle="Connect Global Care offers comprehensive home care services designed to meet your individual needs. Our compassionate and qualified caregivers provide a wide range of services to help you live a fulfilling life at home, safely and with dignity"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="moon"
                title="Mental Health Care"
                subtitle="We understand that mental health is just as important as physical health. Our team of qualified and empathetic therapists provides a safe and 
                supportive space for you to explore your thoughts, feelings, and experiences"
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
              icon="baby" 
              title="Paediatric Home Care" 
              subtitle="We offer comprehensive, compassionate and compassionate home care services for children and families." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">A few words about company</h4>
                <h2 className="font40 extraBold">Why Choose Connect Global Care</h2>
                <p className="font12">
                Connect Global Care goes beyond just healthcare. 
                We are committed to providing a supportive and empowering environment 
                that fosters your overall well-being.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={() => alert("clicked")} />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={physio1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={add5} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={add12} alt="office" />
                    </AddImgWrapp3>
                    {/* <AddImgWrapp4>
                      <img src={add8} alt="office" />
                    </AddImgWrapp4> */}
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
    }




const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;


