
import React, { useEffect, useState, useContext} from "react";
import { jwtDecode } from 'jwt-decode';
import AuthContext from "../context/AuthContext";
// import  Link  from "react-router-dom";
import './Navbar.css';


import styled from "styled-components";
import { Link } from "react-scroll";
import Backdrop from "../Elements/Backdrop";
import Sidebar from "../Elements/Sidebar";

import p_logo from "../assets/svg/p_logo.svg"
// import day from '../assets/day.svg';
// import night from '../assets/night.svg';
// import search_L from '../assets/search_L.svg';
// import search_D from '../assets/search_D.svg';
// import toggle_L from '../assets/toggle_L.svg';
// import toggle_D from '../assets/toggle_D.svg';
// import logo_L from '../assets/logo_L.svg';
// import logo_D from '../assets/logo_D.svg';
import BurgerIcon from '../assets/svg/BurgerIcon';
import LogoIcon from '../assets/svg/Logo'



export default function Navbar() {

  const { user, logoutUser, loginUser, registerUser} = useContext(AuthContext);
  
  const token = localStorage.getItem('authTokens');

  if (token) {
    const decoded = jwtDecode(token);
    var user_id = decoded.user_id;
  }


    const [y, setY] = useState(window.scrollY);
      const [sidebarOpen, toggleSidebar] = useState(false);

      // const Navbar = ({theme, setTheme}) => {
      //     const toggle_mode = () => {
      //         theme === 'light' ? setTheme('dark') : setTheme('light');
      //     }
    
      useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
          window.removeEventListener("scroll", () => setY(window.scrollY));
        };
      }, [y]);
    
  return (
    <div className=''>
        <>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <div>
              <img src={p_logo} alt="C.G.C" width={100} height={100} />
            </div>
           
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                Recruitment
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="blog" spy={true} smooth={true} offset={-80}>
                Blog
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="pricing" spy={true} smooth={true} offset={-80}>
                About Us
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                Contact
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {token === null && 
            <>
              <li className="semiBold font15 pointer">
                <Link to="/login" onClick={(e) => {
                  e.preventDefault();
                  loginUser(e, 'email', 'password')
                }} style={{ padding: "10px 30px 10px 0" }}>
                  Log in
                </Link>
              </li>
              <li className="semiBold font15 pointer flexCenter">
                <Link to="/register"  onClick={(e) => {
                  e.preventDefault(); 
                  registerUser( e, registerUser)
                }} className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                  Get Started
                </Link>
              </li>
            </>

            }

            {token !== null && 
            <>
              <li className="semiBold font15 pointer">
                <Link onClick={logoutUser} style={{ padding: "10px 30px 10px 0" }}>
                  Log out
                </Link>
              </li>
              <li className="semiBold font15 pointer flexCenter">
                <Link to="/register" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                  Get Started
                </Link>
              </li>
            </>

            }
           
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
        
        </>


      {/* <img src={theme ==='light' ? logo_L : logo_D} alt="" className='logo' />

      <ul>
        <li>Home</li>
        <li>Healthcare Services</li>
        <li>Recruitment</li>
        <li>About Us</li>
        <li>Reach Out</li>
      </ul>

      <div className='search-box'>
        <input type="text" placeholder='Search' />
        <img src={theme === 'light' ? search_L : search_D} alt="" className='search_icon'/>
      </div>

      <img onClick={()=> {toggle_mode()}} src={theme === 'light' ? night : day} alt="" className='toggle_icon' /> */}

    </div>
  )
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;




// export default Navbar
