import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';

import Home from './views/Home';
import Register from './views/Register';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Navbar from './views/Navbar';
import JobListings from './JobListings'
import HealthcareServices from './HealthcareServices';
import { Helmet } from "react-helmet";
import Landing from "./screens/Home.jsx";

function App() {

  const currrent_theme = localStorage.getItem('current_theme');

  const [ theme, setTheme] = useState(currrent_theme ? currrent_theme : 'light');

  useEffect(()=>{
    localStorage.setItem('current_theme', theme);
  },[theme])

  return (
    <Router>
      <AuthProvider>
        <div className={`container ${theme}`}>
      <Navbar theme={theme} setTheme={setTheme}/>
    </div>
        <Switch>
          <PrivateRoute component={Dashboard} path='/dashboard' exact />
          <Route component={Login} path='/login' />
          <Route component={Register} path='/register' />
          <Route component={Home} path='/' />
          {/* <Landing /> */}


      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
        </Switch>
       </AuthProvider>
     </Router>
    
  )
}

export default App
