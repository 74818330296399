import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
            <p className="font13">
            Welcome to the Connect Global Care Blog: Your Source for Healthcare Insights and Inspiration
              <br />
              
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="The Power of Companionship: How Home Care Can Enhance Well-being"
                text="Loneliness can significantly impact physical and mental health, 
                especially for older adults living alone. Home care services offer more 
                than just assistance with daily tasks; they provide companionship and social
                 interaction,reducing feelings of isolation and promoting emotional well-being."
                tag="company"
                author="Sarah Jones, RN, Connect Global Care Caregiver Coordinator, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Finding the Right Therapist: A Guide to Mental Health Support"
                text="Mental health challenges are more prevalent than ever, and seeking professional support is a sign of strength. 
                But navigating the search for a therapist can feel overwhelming."
                tag="company"
                author="Dr. Michael Lee, Psychologist,, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Technology in Home Care: Empowering Independence and Safety

                "
                text="Technological advancements are increasingly playing a valuable role in home care.  This blog explores how wearable devices can monitor vital signs, medication reminders can enhance medication adherence, and video communication 
                tools can facilitate connections between caregivers, patients, and families. "
                tag="company"
                author="David Miller, CGC Technology Integration Specialist, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Building a Rewarding Career in Healthcare: Exploring Your Options"
                text=" The healthcare sector offers a diverse range of rewarding career paths for passionate individuals.  This blog delves into exploring various healthcare professions, 
                from nursing and therapy to pharmacy and allied health roles. "
                tag="company"
                author="Emily Brown, CGC Recruitment Specialist, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="The Importance of Overnight Care"
                text="For families caring for loved ones with nighttime needs, sleep deprivation and worry can become significant burdens.  Overnight care services offer a valuable solution.  This blog highlights the benefits of overnight care, 
                including monitoring and assistance, medication reminders, and companionship. "
                tag="company"
                author="Maria Garcia, RN, CGC Overnight Care Supervisor, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Caring for Our Caregivers: Supporting the Backbone of Healthcare"
                text=" Caregivers play a crucial role in healthcare, providing essential support and compassion to patients and families.  
                This blog recognizes the importance of caregiver well-being.  "
                tag="company"
                author="Jessica Thompson, CGC Human Resources Manager, 2 days ago"
                action={() => alert("You are being redirected to external page")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("You are being redirected to external page")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;