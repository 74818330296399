import React from 'react'
import{useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import './views.css';
import 'boxicons'
import AuthContext from '../context/AuthContext';





function Register() {

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');

  const {registerUser} = useContext(AuthContext)

 



  const handleSubmit = async e => {
    e.preventDefault()
    
  
    

    registerUser(email, username, password, password2)

   

  }



  return (
    <div>
      <>
       <div className='register-container'>
      <div className='container'>
        <div className='content'>
          <h2 className='logo'><box-icon name='health' color="white"></box-icon>C.G.C</h2>

          <div className='text-sci'>
            <h2>Welcome to Connect Global Care Ltd! <br/><span>Create your Account here.</span></h2>

             <p>lorem ipsum</p>

             <div className='social-icons'>
                <a href="#"><box-icon type='logo' name='facebook-circle'size="md" animation="tada-hover" color="white" ></box-icon></a>
                <a href="#"><box-icon name='instagram-alt' type='logo' size="md" animation="tada-hover" color='white' ></box-icon></a>
                <a href="#"><box-icon name='twitter' type='logo'  size="md" animation="tada-hover" color='white' ></box-icon></a>
              </div>
          </div>
        </div>
      </div>
      <h1 className="register-title">Register</h1>
      <div className='logreg-box'>
      <div className='form-box login'>
        <form className="register-form" onSubmit={handleSubmit}>
          <div className='input-box'>
            <span><box-icon type='solid' name='envelope'></box-icon></span>
            <label htmlFor="email">Email</label>
            <input type="Email" 
              id="email" 
              placeholder='example@gmail.com' 
              required 
              className="register-input" 
              onChange={(e) => setEmail(e.target.value)}
              />
          
          </div>

          <div className='input-box'> 
            <span><box-icon name='user' type='solid' ></box-icon></span>
            <label htmlFor="username">Username</label>
            <input type="text" 
              id="username" 
              required 
              className="register-input" 
              onChange={(e) => setUsername(e.target.value)}
              />
          </div>

          <div className='input-box'> 
            <span><box-icon name='lock-alt' type='solid' ></box-icon></span>
            <label htmlFor="password">Password</label>
            <input type="password" 
              id="password" 
              required 
              className="register-input" 
              onChange={(e) => setPassword(e.target.value)}
              />
          </div>

          <div className='input-box'> 
            <label htmlFor="password2">Confirm Password</label>
            <input type="password" 
            id="password2" 
            required 
            className="register-input" 
            onChange={(e) => setPassword2 (e.target.value)}
            />
          </div>
          
          
          <button type="submit" className="register-button">Sign Up</button>

          <div className='login-register'>
            <p>Already have an Account? <a href="/login">Login</a></p>
          </div>
        </form>
        </div>
      </div>
   
    </div>
  
      </>
    </div>
  ); 
}
export default Register
