import { createContext, useState, useEffect } from "react";
import jwt_decode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { json, useHistory } from 'react-router-dom';

const swal = require('sweetalert2')


const AuthContext = createContext();

export default AuthContext

export const AuthProvider = ({ children }) => {
    const [ AuthTokens, setAuthTokens ] = useState(() => 
                localStorage.getItem('AuthTokens') ? JSON.parse(localStorage.getItem('AuthTokens')) : null

    );

    const [ user, setUser ] = useState(() => 
        localStorage.getItem('AuthTokens') ? jwtDecode(localStorage.getItem('AuthTokens')) : null
    );
    

    const [ loading, setLoading ] = useState(true)

    const history = useHistory()

    // const loginUser = async(e, email, password) => {
    //     e.preventDefault();
    //     const response = await fetch('http://127.0.0.1:8000/api/token/', {
    //         method: 'POST',
    //         headers:{ 'content-Type':'application/json'},
    //         body: JSON.stringify({email, password})
            
    //     })

    //     const data = await response.json()
      

    //     if(response.status === 200){
            
    //         setAuthTokens(data)
    //         setUser(jwtDecode(data.access))
    //         localStorage.setItem('AuthTokens', JSON.stringify(data))
    //         history.push("/")
    //         swal.fire({
    //             title: 'Login Successful',
    //             icon: 'success',
    //             toast: true,
    //             timer: 6000,
    //             position: 'top-right',
    //             timerProgressBar: true,
    //             showConfirmButton: false,
    //         })

    //     } else {
    //         swal.fire({
    //             title: 'Username and Password does not exist',
    //             icon: 'error',
    //             toast: true,
    //             timer: 6000,
    //             position: 'top-right',
    //             timerProgressBar: true,
    //             showConfirmButton: false,
    //         })
    //     }
    // }


    const loginUser = async(e, email, password) => {
        e.preventDefault(); // Prevent the default form submission behavior
    
        const response = await fetch('http://127.0.0.1:8000/api/token/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
    
        const data = await response.json();
    
        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem('AuthTokens', JSON.stringify(data));
            history.push("/"); // Redirect to the home page upon successful login
            swal.fire({
                title: 'Login Successful',
                icon: 'success',
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            swal.fire({
                title: 'Username and Password does not exist',
                icon: 'error',
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    }

    const registerUser = async(e, email, username, password, password2) => {
        e.preventDefault();
        const response = await fetch('http://127.0.0.1:8000/api/register/', {
            method: 'POST',
            headers: {
                'content-Type':'application/json'
            },
            body: JSON.stringify({
                email, username, password,password2
            })


        
        })
         
        if(response.status === 201){
            history.push('/login')
            swal.fire({
                title: 'Registration Successful, Login Now',
                icon: 'success',
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log('There was a server issue!');
            swal.fire({
                title: 'An Error Occured' + response.status,
                icon: 'error',
                toast: true,
                timer: 6000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        history.push('/login')
        swal.fire({
            title: 'You have been Logged Out...',
            icon: 'success',
            toast: true,
            timer: 6000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        })
    }

    const contextData = {
        user,
        setUser,
        AuthTokens,
        setAuthTokens,
        registerUser,
        loginUser,
        logoutUser,
    }

    useEffect(() => {
        if (AuthTokens) {
            setUser(jwtDecode(AuthTokens.access))
        }
        setLoading(false)
    }, [AuthTokens, loading])

    return(
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}