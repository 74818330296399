import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
// import ProjectImg1 from "../assets/img/projects/1.png";
// import ProjectImg2 from "../assets/img/projects/2.png";
// import ProjectImg3 from "../assets/img/projects/3.png";
// import ProjectImg4 from "../assets/img/projects/4.png";
// import ProjectImg5 from "../assets/img/projects/5.png";
// import ProjectImg6 from "../assets/img/projects/6.png";
// import AddImage2 from "../assets/img/add/add2.png";
import images from  "../assets/img/add/images.jpeg"
import caregiver from "../assets/img/add/caregiver.jpg"
import mentalhe from "../assets/img/add/mentalthe.jpg"
import nurses from "../assets/img/add/nurses.jpg"
import ot from "../assets/img/add/ot.jpg"
import pharma from "../assets/img/add/pharma.jpg"
import physio2 from "../assets/img/add/physio2.jpg"

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Find a Job</h1>
            <p className="font13">
            Are you a passionate healthcare professional seeking a rewarding career opportunity? 
              <br />
              Find Your Perfect Healthcare Job in the UK
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={nurses}
                title="Registered Nurse (RN)"
                text="A Registered nurse will provide comprehensive patient care, 
                administering medications, monitoring vital signs, assisting with daily 
                living activities, and educating patients on their health conditions.  "
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={caregiver}
                title="Caregiver"
                text="A Caregiver will assist 
                individuals with daily living activities such as bathing, dressing, meal 
                preparation, medication reminders, and light housekeeping.  "
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ot}
                title="Occupational Therapist (OT)"
                text="Occupational therapist will help individuals regain or improve their 
                ability to perform daily activities due to injury, illness, or disability. They create personalized therapy plans, recommend assistive 
                devices, and promote independence in areas like self-care, work, and leisure.  "
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={mentalhe}
                title="Mental Health Therapist"
                text=" Mental health therapists provide therapy to individuals and families 
                experiencing mental health 
                challenges like depression, anxiety, relationship issues, and trauma."
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={pharma}
                title="Pharmacist"
                text="Pharmacists dispense medications, counsel patients on proper 
                medication use and side effects, and collaborate with other 
                healthcare professionals to ensure medication safety and effectiveness. "
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={physio2}
                title="Physiotherapist (Physio)"
                text="Physiotherapists help individuals regain mobility and 
                manage pain due to illness, injury, or disability. 
                They create exercise programs, provide manual therapy techniques,
                 and educate patients on how to manage their conditions and 
                 improve physical function.                 "
                action={() => alert("Redirecting to careers page...")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("Redirecting to careers page...")} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={images} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">Why Choose Connect Global Care</h2>
              <p className="font12">
              Connect Global Care goes beyond just healthcare. We are committed to providing a supportive and empowering environment that fosters your overall well-being.  Contact us 
              today to discuss your healthcare needs and learn how we can help you thrive!
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("Redirecting to contact us page...")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("Redirecting to contact us page...")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
