import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Since my father's stroke, it's been a challenge for him to live independently. Connect Global Care's home care services have been a lifesaver. Their caregivers are not just assistants; they're kind companions who truly care about my dad's well-being. He feels 
            safe and supported at home, and I have peace of mind knowing he's in good hands. Thank you, Connect Global Care!"
            author=" Sarah Miller, Daughter of Home Care Client"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Finding the right therapist can be daunting.  Connect Global Care made the process seamless. They matched me with a therapist who perfectly understood my needs. Therapy has been a transformative experience, helping me manage my anxiety and build healthier coping mechanisms. 
            I feel empowered and hopeful about the future, and I owe a lot of that to Connect Global Care's guidance and support."
            author="David Peterson, Therapist Client"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I've been a nurse for over 15 years, and transitioning to home care with Connect Global Care was the best decision I've made.  The company fosters a supportive and collaborative environment.  We have the autonomy to provide exceptional care tailored to each client's needs.  
            It's incredibly rewarding to see the positive impact we have on people's lives in the comfort of their own homes."
            author="Jane Williams, Registered Nurse"
          />
        </LogoWrapper>
        {/* <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it."
            author="Ralph Waldo Emerson"
          />
        </LogoWrapper> */}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
